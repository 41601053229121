import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosInstance} from "axios";
import {
  AppDispatch,
  State
} from "../types/state.js";
import {
  Post,
  Posts
} from "../types/post";
import {
  PhotoGalleries,
  PhotoGallery,
  Videos
} from "../types/media";
import {APIRoute} from "../constants/api-constants";
import {
  Chapter,
  Chapters,
  PageType,
  Socials
} from "../types/common";
import {ProcurementsType} from "../types/procurement";
import {Feedbacks} from "../types/feedback";
import {Feedback} from "../types/create-feedback";
import {
  Team,
  TeamGroup,
  Teams,
  TeamsStats,
  TeamStats,
  TournamentTeamsGroup
} from "../types/team";
import {
  Player,
  PlayerGroup,
  PlayerItem,
  PlayerItems,
  Players,
  PlayerStatsGroup,
  PlayerStats,
  Position,
  TeamPlayersGroup,
} from "../types/player";
import {Clubs} from "../types/club";
import {RepresentativeItem, RepresentativeItems} from "../types/representative";
import {
  Tournament,
  TournamentGroup,
  Tournaments,
  TournamentsStats,
  TournamentStats
} from "../types/tournament";
import {
  Match,
  Matches,
  MatchGoals,
  Referees
} from "../types/match";
import {
  Banner,
  Banners
} from "../types/banner";
import {Partners} from "../types/partners";
import {Trophies} from "../types/trophy";
import {
  Stadium,
  StadiumGroup
} from "../types/stadium";
import {
  TeamsTables,
  TeamsTablesGroup
} from "../types/teams-tables";
import {
  Season,
  SeasonGroup,
  Seasons
} from "../types/season";
import {Rounds} from "../types/rounds";
import {ChessData} from "../types/chess";
import {
  Cities,
  City
} from "../types/city.js";
import { PAGESIZE } from '../constants/common'

export const fetchTournaments = createAsyncThunk<
  Tournaments,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("tournaments/fetchTournaments", async (_arg, {extra: api}) => {
  const {data} = await api.get<Tournaments>(`${APIRoute.Tournaments}?pageSize=${PAGESIZE}`);
  return data;
});

export const fetchTournament = createAsyncThunk<
  Tournament,
  Tournament["tournament_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  "tournaments/fetchTournament",
  async (tournamentId: number, {extra: api}) => {
    const {data} = await api.get<Tournament>(
      `${APIRoute.Tournaments}/${tournamentId}`
    );
    return data;
  }
);

export const fetchGroupTournament = createAsyncThunk<
  TournamentGroup,
  Tournament["tournament_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  "tournaments/fetchGroupTournament",
  async (tournamentId: number, {extra: api}) => {
    const {data} = await api.get<Tournament>(
      `${APIRoute.Tournaments}/${tournamentId}`
    );
    const tournament = data;
    return {tournamentId, tournament};
  }
);

export const fetchTournamentTeams = createAsyncThunk<
  Teams,
  Tournament["tournament_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  "teams/fetchTournamentTeams",
  async (tournamentId: number, {extra: api}) => {
    const {data} = await api.get<Teams>(
      `${APIRoute.Tournaments}/${tournamentId}${APIRoute.Teams}?pageSize=${PAGESIZE}`
    );
    return data;
  }
);

export const fetchGroupTournamentTeams = createAsyncThunk<
  TournamentTeamsGroup,
  Tournament["tournament_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  "teams/fetchGroupTournamentTeams",
  async (tournamentId: number, {extra: api}) => {
    const {data} = await api.get<Teams>(
      `${APIRoute.Tournaments}/${tournamentId}${APIRoute.Teams}?pageSize=${PAGESIZE}`
    );
    const teams = data;
    return {id: tournamentId, teams};
  }
);

export const fetchTournamentStats = createAsyncThunk<
  TournamentsStats,
  Tournament["tournament_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  "tournaments/fetchTournamentStats",
  async (tournamentId: number, {extra: api}) => {
    const {data} = await api.get<TournamentStats>(
      `${APIRoute.TournamentStats}/${tournamentId}`
    );
    const stats = data;
    return {tournamentId, stats};
  }
);

export const fetchTournamentMatches = createAsyncThunk<
  Matches,
  Tournament["tournament_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  "matches/fetchTournamentMatches",
  async (tournamentId: number, {extra: api}) => {
    const {data} = await api.get<Matches>(
      `${APIRoute.Tournaments}/${tournamentId}${APIRoute.Matches}?pageSize=${PAGESIZE}`,
      {params: {with: "team1,team2"}}
    );
    return data;
  }
);

export const fetchMatches = createAsyncThunk<
  Matches,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("matches/fetchMatches", async (_arg, {extra: api}) => {
  const {data} = await api.get<Matches>(`${APIRoute.Matches}?pageSize=${PAGESIZE}`);
  return data;
});

export const fetchMatch = createAsyncThunk<
  Match,
  Match["match_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("matches/fetchMatch", async (matchId: number, {extra: api}) => {
  const {data} = await api.get<Match>(`${APIRoute.Matches}/${matchId}`, {
    params: {
      with: "protocol,stadium,tournament,team1,team2,goalkeepers,goals,matchPlayers1,matchPlayers2,matchStaff,stats,unrealizedPenalties",
    },
  });
  return data;
});

export const fetchMatchGoals = createAsyncThunk<
  MatchGoals,
  Match["match_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("matches/fetchMatchGoals", async (matchId: number, {extra: api}) => {
  const {data} = await api.get<MatchGoals>(
    `${APIRoute.Matches}/${matchId}${APIRoute.MatchGoals}`
  );
  return data;
});

export const fetchMatchGoalkeepers = createAsyncThunk<
  PlayerItems,
  Match["match_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("matches/fetchMatchGoalkeepers", async (matchId: number, {extra: api}) => {
  const {data} = await api.get<PlayerItems>(
    `${APIRoute.Matches}/${matchId}${APIRoute.MatchGoalkeepers}`
  );
  return data;
});

export const fetchMatchReferees = createAsyncThunk<
  Referees,
  Match["match_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("matches/fetchMatchReferees", async (matchId: number, {extra: api}) => {
  const {data} = await api.get<Referees>(
    `${APIRoute.Matches}/${matchId}${APIRoute.Referees}`
  );
  return data;
});

export const fetchStadium = createAsyncThunk<
  Stadium,
  Stadium["stadium_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("stadiums/fetchStadium", async (stadiumId: number, {extra: api}) => {
  const {data} = await api.get<Stadium>(`${APIRoute.Stadiums}/${stadiumId}`);
  return data;
});

export const fetchGroupStadium = createAsyncThunk<
  StadiumGroup,
  Stadium["stadium_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("stadiums/fetchGroupStadium", async (stadiumId: number, {extra: api}) => {
  const {data} = await api.get<Stadium>(`${APIRoute.Stadiums}/${stadiumId}`);
  const stadium = data;
  return {id: stadiumId, stadium};
});

export const fetchSeasons = createAsyncThunk<
  Seasons,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("seasons/fetchSeasons", async (_arg, {extra: api}) => {
  const {data} = await api.get<Seasons>(`${APIRoute.Seasons}?pageSize=${PAGESIZE}`);
  return data;
});

export const fetchSeason = createAsyncThunk<
  Season,
  Season["season_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("seasons/fetchSeason", async (seasonId: number, {extra: api}) => {
  const {data} = await api.get<Season>(`${APIRoute.Seasons}/${seasonId}`);
  return data;
});

export const fetchGroupSeason = createAsyncThunk<
  SeasonGroup,
  Season["season_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("seasons/fetchGroupSeason", async (seasonId: number, {extra: api}) => {
  const {data} = await api.get<Season>(`${APIRoute.Seasons}/${seasonId}`);
  const season = data;
  return {id: seasonId, season};
});

export const fetchPosts = createAsyncThunk<
  Posts,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("posts/fetchPosts", async (_arg, {extra: api}) => {
  const {data} = await api.get<Posts>(`${APIRoute.Posts}?pageSize=${PAGESIZE}`);
  return data;
});

export const fetchPost = createAsyncThunk<
  Post,
  Post["post_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("posts/fetchPost", async (postId: number, {extra: api}) => {
  const {data} = await api.get<Post>(`${APIRoute.Posts}/${postId}`);
  return data;
});

export const fetchPhotoGalleries = createAsyncThunk<
  PhotoGalleries,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("photos/fetchPhotoGalleries", async (_arg, {extra: api}) => {
  const {data} = await api.get<PhotoGalleries>(`${APIRoute.PhotoGalleries}?pageSize=${PAGESIZE}`);
  return data;
});

export const fetchPhotoGallery = createAsyncThunk<
  PhotoGallery,
  PhotoGallery["album_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  "photos/fetchPhotoGallery",
  async (photoGalleryId: number, {extra: api}) => {
    const {data} = await api.get<PhotoGallery>(
      `${APIRoute.PhotoGalleries}/${photoGalleryId}`
    );
    return data;
  }
);

export const fetchMediaPage = createAsyncThunk<
  PageType,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("media/fetchMediaPage", async (_arg, {extra: api}) => {
  const {data} = await api.get<PageType>(APIRoute.MediaPage);
  return data;
});

export const fetchVideos = createAsyncThunk<
  Videos,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("videos/fetchVideos", async (_arg, {extra: api}) => {
  const {data} = await api.get<Videos>(`${APIRoute.Videos}?pageSize=${PAGESIZE}`);
  return data;
});

export const fetchProcurements = createAsyncThunk<
  ProcurementsType,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("procurements/fetchProcurements", async (_arg, {extra: api}) => {
  const {data} = await api.get<ProcurementsType>(APIRoute.Procurements);
  return data;
});

export const fetchProcurementsPage = createAsyncThunk<
  PageType,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("procurements/fetchProcurementsPage", async (_arg, {extra: api}) => {
  const {data} = await api.get<PageType>(APIRoute.ProcurementsPage);
  return data;
});

export const fetchFeedbacks = createAsyncThunk<
  Feedbacks,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("feedbacks/fetchFeedbacks", async (_arg, {extra: api}) => {
  const {data} = await api.get<Feedbacks>(APIRoute.Feedbacks);
  return data;
});

export const fetchFeedbacksPage = createAsyncThunk<
  PageType,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("feedbacks/fetchFeedbacksPage", async (_arg, {extra: api}) => {
  const {data} = await api.get<PageType>(APIRoute.FeedbacksPage);
  return data;
});

export const fetchMassSkatingPage = createAsyncThunk<
  PageType,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("massSkating/fetchMassSkatingPage", async (_arg, {extra: api}) => {
  const {data} = await api.get<PageType>(APIRoute.MassSkatingPage);
  return data;
});

export const sendFeedback = createAsyncThunk<
  void,
  Feedback,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("createFeedback/sendFeedback", async (feedback, {extra: api}) => {
  await api.post(APIRoute.CreateFeedback, feedback);
});

export const fetchContactsPage = createAsyncThunk<
  PageType,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("contacts/fetchContactsPage", async (_arg, {extra: api}) => {
  const {data} = await api.get<PageType>(APIRoute.ContactsPage);
  return data;
});

export const fetchTeams = createAsyncThunk<
  Teams,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("teams/fetchTeams", async (_arg, {extra: api}) => {
  const {data} = await api.get<Teams>(`${APIRoute.Teams}?pageSize=${PAGESIZE}`);
  return data;
});

export const fetchTeam = createAsyncThunk<
  Team,
  Team["team_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("teams/fetchTeam", async (teamId: number, {extra: api}) => {
  const {data} = await api.get<Team>(`${APIRoute.Teams}/${teamId}`);
  return data;
});

export const fetchGroupTeam = createAsyncThunk<
  TeamGroup,
  Team["team_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("teams/fetchGroupTeam", async (teamId: number, {extra: api}) => {
  const {data} = await api.get<Team>(`${APIRoute.Teams}/${teamId}`);
  const team = data;
  return {id: teamId, team};
});

export const fetchTeamStats = createAsyncThunk<
  TeamsStats,
  {
    teamId: number;
    groupBy: string;
    tournamentId?: number;
    seasonId?: number;
    roundId?: number;
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  "teams/fetchTeamStats",
  async (
    {teamId, groupBy, tournamentId, seasonId, roundId},
    {extra: api}
  ) => {
    let url = `${APIRoute.TeamStats}/${teamId}?groupBy=${groupBy}&tournament_id=${tournamentId}`;
    if (seasonId) {
      url += `&season_id=${seasonId}`;
    }
    if (roundId) {
      url += `&round_id=${roundId}`;
    }
    const {data} = await api.get<TeamStats[]>(url);
    const stats = data;
    return {teamId, stats};
  }
);

export const fetchTeamPlayers = createAsyncThunk<
  PlayerItems,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("teamPlayers/fetchTeamPlayers", async (teamId: number, {extra: api}) => {
  const {data} = await api.get<PlayerItems>(
    `${APIRoute.Teams}/${teamId}${APIRoute.Players}`
  );
  return data;
});

export const fetchTeamPlayersGroup = createAsyncThunk<
  TeamPlayersGroup,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("teamPlayers/fetchTeamPlayersGroup", async (teamId: number, {extra: api}) => {
  const {data} = await api.get<PlayerItems>(
    `${APIRoute.Teams}/${teamId}${APIRoute.Players}`
  );
  const players = data;
  return {teamId, players};
});

export const fetchMatchPlayers = createAsyncThunk<
  Players,
  Match["match_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("players/fetchMatchPlayers", async (matchId: number, {extra: api}) => {
  const {data} = await api.get<Players>(
    `${APIRoute.Matches}/${matchId}${APIRoute.Players}`
  );
  return data;
});

export const fetchUnrealizedPenalties = createAsyncThunk<
  MatchGoals,
  Match["match_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  "matches/fetchUnrealizedPenalties",
  async (matchId: number, {extra: api}) => {
    const {data} = await api.get<MatchGoals>(
      `${APIRoute.Matches}/${matchId}${APIRoute.UnrealizedPenalties}`
    );
    return data;
  }
);

export const fetchAllPlayers = createAsyncThunk<
  Players,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("players/fetchAllPlayers", async (_arg, {extra: api}) => {
  const {data} = await api.get<Players>(`${APIRoute.Players}?pageSize=${PAGESIZE}`);
  return data;
});

export const fetchPlayer = createAsyncThunk<
  PlayerItem,
  PlayerItem["player_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("players/fetchPlayer", async (playerId: number, {extra: api}) => {
  const {data} = await api.get<PlayerItem>(`${APIRoute.Players}/${playerId}`);
  return data;
});

export const fetchGroupPlayer = createAsyncThunk<
  PlayerGroup,
  Player["player_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("players/fetchGroupPlayer", async (playerId: number, {extra: api}) => {
  const {data} = await api.get<Player>(`${APIRoute.Players}/${playerId}`);
  const player = data;
  return {playerId, player};
});

export const fetchPlayerStatsGroup = createAsyncThunk<
  PlayerStatsGroup,
  {
    playerId: number;
    groupBy: string;
    teamId?: number;
    tournamentId?: number;
    seasonId?: number;
    roundId?: number;
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  "playerStats/fetchPlayerStatsGroup",
  async (
    { playerId, groupBy, teamId, tournamentId, seasonId, roundId },
    { extra: api }
  ) => {
    let url = `${APIRoute.PlayerStats}/${playerId}?groupBy=${groupBy}`;
    if (teamId) {
      url += `&team_id=${teamId}`;
    }
    if (tournamentId) {
      url += `&tournament_id=${tournamentId}`;
    }
    if (seasonId) {
      url += `&season_id=${seasonId}`;
    }
    if (roundId) {
      url += `&round_id=${roundId}`;
    }

    const { data } = await api.get<PlayerStats[]>(url);
    const stats = data;
    return { playerId, stats };
  }
);

export const fetchPosition = createAsyncThunk<
  Position,
  Position["position_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("players/fetchPosition", async (positionId: number, {extra: api}) => {
  const {data} = await api.get<Position>(
    `${APIRoute.Positions}/${positionId}`
  );
  return data;
});

export const fetchRepresentatives = createAsyncThunk<
  RepresentativeItems,
  Team["team_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  "representatives/fetchRepresentatives",
  async (teamId: number, {extra: api}) => {
    const {data} = await api.get<RepresentativeItems>(
      `${APIRoute.Teams}/${teamId}${APIRoute.Representatives}`
    );

    return data;
  }
);

export const fetchClubs = createAsyncThunk<
  Clubs,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("clubs/fetchClubs", async (_arg, {extra: api}) => {
  const {data} = await api.get<Clubs>(`${APIRoute.Clubs}?pageSize=${PAGESIZE}`);
  return data;
});

export const fetchClubAttributesPage = createAsyncThunk<
  PageType,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("clubs/fetchClubAttributesPage", async (_arg, {extra: api}) => {
  const {data} = await api.get<PageType>(APIRoute.ClubAttributesPage);
  return data;
});

export const fetchInternationalMatchesPage = createAsyncThunk<
  PageType,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("clubs/fetchInternationalMatchesPage", async (_arg, {extra: api}) => {
  const {data} = await api.get<PageType>(APIRoute.InternationalMatchesPage);
  return data;
});

export const fetchCountryCupsPage = createAsyncThunk<
  PageType,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("clubs/fetchCountryCupsPage", async (_arg, {extra: api}) => {
  const {data} = await api.get<PageType>(APIRoute.CountryCupsPage);
  return data;
});

export const fetchPastPresentFuturePage = createAsyncThunk<
  PageType,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("clubs/fetchPastPresentFuturePage", async (_arg, {extra: api}) => {
  const {data} = await api.get<PageType>(APIRoute.PastPresentFuturePage);
  return data;
});

export const fetchCountryTournamentsPage = createAsyncThunk<
  PageType,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("clubs/fetchCountryTournamentsPage", async (_arg, {extra: api}) => {
  const {data} = await api.get<PageType>(APIRoute.CountryTournamentsPage);
  return data;
});

export const fetchAchievementsPage = createAsyncThunk<
  PageType,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("clubs/fetchAchievementsPage", async (_arg, {extra: api}) => {
  const {data} = await api.get<PageType>(APIRoute.AchievementsPage);
  return data;
});

export const fetchSymbolismAnthemPage = createAsyncThunk<
  PageType,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("clubs/fetchSymbolismAnthemPage", async (_arg, {extra: api}) => {
  const {data} = await api.get<PageType>(APIRoute.SymbolismAnthemPage);
  return data;
});

export const fetchBanners = createAsyncThunk<
  Banners,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("banners/fetchBanners", async (_arg, {extra: api}) => {
  const {data} = await api.get<Banners>(APIRoute.Banners);
  return data;
});

export const fetchBanner = createAsyncThunk<
  Banner,
  Banner["banner_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("banners/fetchBanner", async (bannerId: number, {extra: api}) => {
  const {data} = await api.get<Banner>(`${APIRoute.Banners}/${bannerId}`);
  return data;
});

export const fetchPartners = createAsyncThunk<
  Partners,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("partners/fetchPartners", async (_arg, {extra: api}) => {
  const {data} = await api.get<Partners>(`${APIRoute.Partners}?pageSize=${PAGESIZE}`);
  return data;
});

export const fetchTrophies = createAsyncThunk<
  Trophies,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("trophies/fetchTrophies", async (_arg, {extra: api}) => {
  const {data} = await api.get<Trophies>(`${APIRoute.Trophies}?pageSize=${PAGESIZE}`);
  return data;
});

export const fetchSocials = createAsyncThunk<
  Socials,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("socials/fetchSocials", async (_arg, {extra: api}) => {
  const {data} = await api.get<Socials>(APIRoute.Socials);
  return data;
});

export const fetchTeamsTables = createAsyncThunk<
  TeamsTables,
  { tournamentId: number; roundId?: number },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  "tableTeams/fetchTableTeams",
  async ({tournamentId, roundId}, {extra: api}) => {
    let url = `${APIRoute.Rounds}/${tournamentId}${APIRoute.Tables}`;
    if (roundId !== undefined) {
      url += `?round_id=${roundId}`;
    }
    const {data} = await api.get<TeamsTables>(url);
    return data;
  }
);

export const fetchTeamsTablesGroup = createAsyncThunk<
  TeamsTablesGroup,
  { tournamentId: number; roundId?: number },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  "tableTeams/fetchTableTeamsGroup",
  async ({tournamentId, roundId}, {extra: api}) => {
    let url = `${APIRoute.Rounds}/${tournamentId}${APIRoute.Tables}`;
    if (roundId !== undefined) {
      url += `?round_id=${roundId}`;
    }
    const {data} = await api.get<TeamsTables>(url);
    return {id: roundId, teamsTables: data};
  }
);

export const fetchRounds = createAsyncThunk<
  Rounds,
  Tournament["tournament_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("rounds/fetchRounds", async (tournamentId: number, {extra: api}) => {
  const {data} = await api.get<Rounds>(
    `${APIRoute.Tournaments}/${tournamentId}${APIRoute.Rounds}`
  );
  return data;
});

export const fetchTeamRounds = createAsyncThunk<
  Rounds,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("teamRounds/fetchTeamRounds", async (teamId: number, {extra: api}) => {
  const {data} = await api.get<Rounds>(
    `${APIRoute.Rounds}/${teamId}${APIRoute.Teams}`,
    {params: {with: 'tournament'}}
  );
  return data;
});

export const fetchRoundTeams = createAsyncThunk<
  Teams,
  {
    tournamentId: number,
    roundId: number
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  "roundTeams/fetchRoundTeams",
  async (parameters, {extra: api}) => {
    const {data} = await api.get<Teams>(
      `${APIRoute.Tournaments}/${parameters.tournamentId}${APIRoute.Rounds}/${parameters.roundId}${APIRoute.Teams}`
    );
    return data;
  }
);

export const fetchRoundPlayers = createAsyncThunk<
  Players,
  {
    tournamentId: number,
    roundId: number
  },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  "roundPlayers/fetchRoundPlayers",
  async (parameters, {extra: api}) => {
    const {data} = await api.get<Players>(
      `${APIRoute.Tournaments}/${parameters.tournamentId}${APIRoute.Rounds}/${parameters.roundId}${APIRoute.Players}`
    );
    return data;
  }
);

export const fetchChess = createAsyncThunk<
  ChessData,
  { tournamentId: number; roundId: number },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("chess/fetchChess", async ({tournamentId, roundId}, {extra: api}) => {
  const {data} = await api.get<ChessData>(
    `${APIRoute.Tournaments}/${tournamentId}${APIRoute.Rounds}/${roundId}${APIRoute.Chess}`
  );
  return data;
});

export const fetchCities = createAsyncThunk<
  Cities,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("cities/fetchCities", async (_arg, {extra: api}) => {
  const {data} = await api.get<Cities>(APIRoute.Cities);
  return data;
});

export const fetchCity = createAsyncThunk<
  City,
  City["city_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("cities/fetchCity", async (cityId: number, {extra: api}) => {
  const {data} = await api.get<City>(`${APIRoute.Cities}/${cityId}`);
  return data;
});

export const fetchProgramsPage = createAsyncThunk<
  PageType,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("clubs/fetchProgramsPage", async (_arg, {extra: api}) => {
  const {data} = await api.get<PageType>(APIRoute.ProgramsPage);
  return data;
});

export const fetchSearchedPosts = createAsyncThunk<
  Posts,
  string,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("searches/fetchSearchedPosts", async (query: string, {extra: api}) => {
  const url = `${APIRoute.SearchPost}?query=${query}&page=1`;
  const {data} = await api.get<Posts>(url);
  return data;
});

export const fetchPlayerMatches = createAsyncThunk<
  Matches,
  { playerId: number },
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("players/fetchPlayerMatches",
  async ({playerId}, {extra: api}) => {
    const url = `${APIRoute.Players}/${playerId}${APIRoute.Matches}?pageSize=${PAGESIZE}`;
    const { data } = await api.get<Matches>(url, {
      params: {
        with: "team1,team2",
      },
    });
    return data;
  }
);

export const fetchGoalkeeperSchoolPage = createAsyncThunk<
  PageType,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("clubs/fetchGoalkeeperSchoolPage", async (_arg, {extra: api}) => {
  const {data} = await api.get<PageType>(APIRoute.GoalkeeperSchoolPage);
  return data;
});

export const fetchChapterById = createAsyncThunk<
  Chapter,
  Chapter['chapter_id'],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("chapter/fetchChapterById", async (chapterId: number, {extra: api}) => {
  const {data} = await api.get<Chapter>(`${APIRoute.Chapter}/${chapterId}`);
  return data;
});

export const fetchChaptersChild = createAsyncThunk<
  Chapters,
  Chapter['chapter_id'],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("chapter/fetchChaptersChild", async (chapterId: number, {extra: api}) => {
  const {data} = await api.get<Chapters>(`${APIRoute.Chapter}/child?id=${chapterId}&page=1`);
  return data;
});

export const fetchPressPage = createAsyncThunk<
  PageType,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("clubs/fetchPressPage", async (_arg, {extra: api}) => {
  const {data} = await api.get<PageType>(APIRoute.PressPage);
  return data;
});

export const fetchRepresentative = createAsyncThunk<
  RepresentativeItem,
  RepresentativeItem["staff_id"],
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>(
  "representatives/fetchRepresentative",
  async (staffId: number, {extra: api}) => {
    const {data} = await api.get<RepresentativeItem>(
      `${APIRoute.Representatives}/${staffId}`
    );

    return data;
  }
);

export const fetchTeamMatches = createAsyncThunk<
  Matches,
  number,
  {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
  }
>("matches/fetchTeamMatches", async (teamId: number, {extra: api}) => {
  const {data} = await api.get<Matches>(
    `${APIRoute.Teams}/${teamId}${APIRoute.Matches}`
  );
  return data;
});